import { createSlice } from '../../../utils/@reduxjs/toolkit'
import { useInjectReducer } from '../../../utils/redux-injectors'
import { IHeaderComponent } from './types'

export const initialState: IHeaderComponent = {
  searchInput: '',
}

const slice = createSlice({
  name: 'headerComponent',
  initialState,
  reducers: {
    setSearchInput(state, action) {
      state.searchInput = action.payload
    },
    reset(state) {
      state.searchInput = ''
    },
  },
})

export const { actions: headerComponentActions, reducer } = slice

export const useHeaderComponentSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer })
  return { actions: slice.actions }
}
