import React from 'react'
import { useSelector } from 'react-redux'
import { selectNotifications } from './slice/selectors'
import { Note } from './Note'
import { useNotificationsSlice } from './slice'

export default function Notifications() {
  // Reducers.
  useNotificationsSlice()

  // Selectors.
  const notifications = useSelector(selectNotifications)
  return (
    <>
      {notifications.length > 0 && (
        <div
          aria-live='assertive'
          className='fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-40'
        >
          <div className='w-full flex flex-col items-center space-y-4 sm:items-end'>
            {notifications.map(({ id, title, message, type }) => (
              <Note key={id} id={id} title={title} message={message} type={type} />
            ))}
          </div>
        </div>
      )}
    </>
  )
}
