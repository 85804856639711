import { createSlice } from '../../../utils/@reduxjs/toolkit'
import { useInjectReducer } from '../../../utils/redux-injectors'
import { IModalComponent } from './types'

export const initialState: IModalComponent = {
  open: false,
  title: '',
  body: '',
  buttons: '',
  icon: '',
}

const slice = createSlice({
  name: 'modalComponent',
  initialState,
  reducers: {
    setOpen(state, action) {
      state.open = action.payload
    },
    setTitle(state, action) {
      state.title = action.payload
    },
    setBody(state, action) {
      state.body = action.payload
    },
    setButtons(state, action) {
      state.buttons = action.payload
    },
    setIcon(state, action) {
      state.icon = action.payload
    },
  },
})

export const { actions: modalComponentActions, reducer } = slice

export const useModalComponentSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer })
  return { actions: slice.actions }
}
