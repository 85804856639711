import { createSlice } from 'utils/@reduxjs/toolkit'
import { useInjectReducer } from 'utils/redux-injectors'
import { IPaedagofixAppComponent } from './types'
import { useInjectSaga } from 'redux-injectors'
import { paedagofixComponentSaga } from './saga'
import { LocationWhichTrainCoaches } from 'types/runtime'

export const initialState: IPaedagofixAppComponent = {
  situation: null,
  situations: null,
  movementStageData: null,
  locationsWhichTrainCoaches: undefined,
  coachRequestIsLoading: false,
  coachRequestSent: false,
  pendingCoachRequests: false,
}

export type LocationsWhichTrainCoachesResponse = {
  type: string
  payload: LocationWhichTrainCoaches[] | undefined
}

export interface ICoachRequestsRequest {
  type: string
  payload: {
    location_id: number
  }
}

export interface ICoachRequestResponse {
  type: string
  payload: boolean
}

export interface IPendingCoachRequestsResponse {
  type: string
  payload: boolean
}

const slice = createSlice({
  name: 'paedagofixAppComponent',
  initialState,
  reducers: {
    locationsWhichTrainCoachesRequest() {},
    locationsWhichTrainCoachesResponse(state, { payload }: LocationsWhichTrainCoachesResponse) {
      state.locationsWhichTrainCoaches = payload
    },

    coachRequestsRequest(state, { payload }: ICoachRequestsRequest) {
      payload && (state.coachRequestIsLoading = true)
    },
    coachRequestsResponse(state, { payload }: ICoachRequestResponse) {
      state.coachRequestIsLoading = true
      state.coachRequestSent = payload
    },

    pendingCoachRequestsRequest() {},
    pendingCoachRequestsResponse(state, { payload }: IPendingCoachRequestsResponse) {
      state.pendingCoachRequests = payload
    },

    setSituation(state, action) {
      state.situation = action.payload
    },
    setSituations(state, action) {
      state.situations = action.payload
    },
    loadMovementStageData(state, action) {},
    loadSituations() {},
    setMovementStageData(state, action) {
      state.movementStageData = action.payload
    },
    reset(state) {
      state.situation = null
      state.situations = null
      state.movementStageData = null
    },
  },
})

export const { actions: paedagofixAppComponentActions, reducer } = slice

export const usePaedagofixAppComponentSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer })
  useInjectSaga({ key: slice.name, saga: paedagofixComponentSaga })
  return { actions: slice.actions }
}
