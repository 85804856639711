import { z } from 'zod'

export const USER_APPLICATIONS_SCHEMA = z.object({
  id: z.number(),
  directus_users_ref: z.object({
    id: z.string(),
    role: z.string(),
  }),
  location_id: z.number(),
  status: z.string(),
})

export const LOCATIONS_WHICH_TRAIN_COACHES_SCHEMA = z.object({
  id: z.number(),
  title: z.string(),
  trains_coaches: z.boolean(),
})

export const USER_APPLICATIONS_ARRAY_SCHEMA = z.array(USER_APPLICATIONS_SCHEMA)
export const LOCATIONS_WHICH_TRAIN_COACHES_ARRAY_SCHEMA = z.array(LOCATIONS_WHICH_TRAIN_COACHES_SCHEMA)

export type UserApplication = z.infer<typeof USER_APPLICATIONS_SCHEMA>
export type LocationWhichTrainCoaches = z.infer<typeof LOCATIONS_WHICH_TRAIN_COACHES_SCHEMA>
