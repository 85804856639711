import { Directus } from '@directus/sdk'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import { CustomDirectusTypes, PaedDownloads } from 'types/custom-directus-types'
import {
  LOCATIONS_WHICH_TRAIN_COACHES_ARRAY_SCHEMA,
  LocationWhichTrainCoaches,
  USER_APPLICATIONS_ARRAY_SCHEMA,
  UserApplication,
} from 'types/runtime'

export const directus = new Directus<CustomDirectusTypes>(process.env.DIRECTUS || '', { auth: { mode: 'json' } })

export const getToken = async () => {
  return await directus.auth.token
}

export const updatePersonalData = async (payload) => {
  await directus.users.me.update(payload)
}

export const updatePassword = async (payload) => {
  await directus.transport.post(`${process.env.DIRECTUS}/api1.0/user/password`, payload)
}

export const loginUser = async (email, password) =>
  await directus.auth.login({
    email,
    password,
  })

export const logoutUser = async () => await directus.auth.logout()

export const getMe = async () => {
  return await directus.users.me.read()
}

export const update = async (id, payload) => {
  await directus.users.updateOne(id, payload)
}

export const getApplications = async (filter = {}) => {
  try {
    const applications = await directus.items('applications').readByQuery({
      fields: ['id', 'location_id', 'directus_users_ref.id', 'directus_users_ref.role', 'status'],
      filter,
    })

    USER_APPLICATIONS_ARRAY_SCHEMA.parse(applications.data)

    return applications.data as UserApplication[]
  } catch (err: unknown) {
    Sentry.captureException(err)
  }
}

export const signContract = async (applicationId) => {
  await directus.transport.post(`/api1.0/user/sign-contract/${applicationId}`)
  await directus.auth.refresh()
}

export const passwordReset = async (email) =>
  axios.post(`${process.env.DIRECTUS}/api1.0/user/password-reset`, {
    email,
  })

export const loadSituations = async (langIsoCode) => {
  try {
    const situations = await directus.items('paed_situations').readByQuery({
      fields: ['id', 'title', 'translations.*', 'movement_stage_ref', 'status'],
      filter: {
        translations: {
          languages_code: {
            code: {
              _eq: langIsoCode,
            },
          },
        },
      },
    })
    return situations.data
  } catch (err: unknown) {
    Sentry.captureException(err)
  }
}

export const loadLocationsWhichTrainCoaches = async () => {
  try {
    const response = await directus.items('locations').readByQuery({
      fields: ['id', 'title', 'trains_coaches'],
      filter: { trains_coaches: { _eq: true } },
    })

    LOCATIONS_WHICH_TRAIN_COACHES_ARRAY_SCHEMA.parse(response.data)

    return response.data as LocationWhichTrainCoaches[]
  } catch (err: unknown) {
    Sentry.captureException(err)
  }
}

export const postCoachRequest = async ({ location_id }: { location_id: number }) => {
  try {
    await directus.transport.post<{ location_id: number }>('/api1.0/coach-request', { location_id })
  } catch (err: unknown) {
    Sentry.captureException(err)
  }
}

export const pendingCoachRequests = async () => {
  try {
    const response = await directus.items('coach_requests').readByQuery({ filter: { status: { _eq: 'pending' } } })
    return response.data
  } catch (err: unknown) {
    Sentry.captureException(err)
  }
}

export const loadDownloads = async () => {
  const response = await directus.transport.get<PaedDownloads>('/api1.0/downloads')

  if (!response.data) {
    throw new Error('No data')
  }

  return response.data
}

export const loadFurtherTrainings = async () => {
  return await directus.items('further_trainings').readByQuery({
    fields: ['id', 'title', 'dates', 'place', 'costs', 'description', 'organizational_matters', 'cta_ref.*.*'],
  })
}

export const loadMovementStageData = async (id, langIsoCode) =>
  await directus.items('paed_movement_stages').readOne(id, {
    fields: ['id', 'title', 'translations.*', 'translations.languages_code.code', 'translations.images.*', 'status'],
    filter: {
      translations: {
        languages_code: {
          code: {
            _eq: langIsoCode,
          },
        },
      },
    },
  })
