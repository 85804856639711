import { DownloadType } from 'utils/types/download.type'

export const normalizeSituations = (situations, searchInput, langIsoCode) => {
  return situations
    .map((situation) => {
      return {
        id: situation.id,
        title: situation.translations.filter((t) => t.languages_code === langIsoCode)[0].title,
        status: situation.status,
        movementStage: situation.movement_stage_ref,
      }
    })
    .filter((situation) => {
      if (searchInput.length > 0) {
        if (situation.title.toLowerCase().indexOf(searchInput.toLowerCase()) !== -1) {
          return situation
        }
      } else {
        return situation
      }
    })
    .sort((a, b) => {
      if (a.title < b.title) {
        return -1
      }
      if (a.title > b.title) {
        return 1
      }
      return 0
    })
    .reduce((accumulator, situation) => {
      const char = situation.title.charAt(0)
      if (!accumulator[char]) {
        accumulator[char] = []
      }
      accumulator[char].push(situation)
      return accumulator
    }, {})
}

const humanFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

  return `${bytes.toFixed(dp)} ${units[u]}`
}

export const normalizeDownloads = (downloads): DownloadType[] => {
  return downloads
    .filter((download) => !!download.download)
    .map((download): DownloadType => {
      return {
        id: download.id,
        fileId: download.download.id,
        title: download.download.title,
        type: download.download.type,
        fileEnding: download.download.filename_download.split('.').pop(),
        filename: download.download.filename_download,
        filesize: humanFileSize(download.download.filesize),
        previewImageId: download.preview_image,
        exclusive_for_coaches: download.exclusive_for_coaches === 'yes',
      }
    })
}

export const normalizeMovementStageData = (movementStageData, langIsoCode) => {
  return {
    id: movementStageData.id,
    title: movementStageData.translations.filter((t) => t.languages_code.code === langIsoCode)[0].title,
    subtitle: movementStageData.translations.filter((t) => t.languages_code.code === langIsoCode)[0].subtitle,
    description: movementStageData.translations.filter((t) => t.languages_code.code === langIsoCode)[0].description,
    images: movementStageData.translations.filter((t) => t.languages_code.code === langIsoCode)[0].images,
    status: movementStageData.status,
  }
}
