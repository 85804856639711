import { createSlice } from '../../../utils/@reduxjs/toolkit'
import { useInjectReducer } from '../../../utils/redux-injectors'
import { ILanguageDropdownComponent } from './types'

export const initialState: ILanguageDropdownComponent = {
  selectedLanguage: 'de',
}

const slice = createSlice({
  name: 'languageDropdownComponent',
  initialState,
  reducers: {
    setSelectedLanguage(state, action) {
      state.selectedLanguage = action.payload
    },
  },
})

export const useLanguageDropdownComponentSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer })
  return { actions: slice.actions }
}
