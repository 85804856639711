import { call, put, takeLatest } from 'redux-saga/effects'
import { actions } from '.'
import axios from 'axios'
import { update, getMe, getToken } from 'components/AuthComponent/slice/api'
import { authComponentActions } from 'components/AuthComponent/slice'
import { notificationsActions } from 'components/Notifications/slice'
import { INotificationsPayload } from 'components/Notifications/types'

export function* uploadFileSaga(action) {
  const {
    payload: {
      acceptedFiles,
      notifications: { success, error },
    },
  }: { payload: { acceptedFiles: File[]; notifications: INotificationsPayload } } = action

  try {
    const form = new FormData()
    form.append('file', acceptedFiles[0])

    const accessToken = yield call(getToken)
    const {
      data: { data },
    } = yield call(axios.post, `${process.env.DIRECTUS}/files`, form, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })

    if (!data?.id) {
      throw new Error('Fehler beim Upload der ID')
    }

    const { id } = yield call(getMe)
    yield call(update, id, { avatar: data.id })
    const { first_name, last_name, email, avatar } = yield call(getMe)
    yield put(
      authComponentActions.setPersonalData({
        firstName: first_name,
        lastName: last_name,
        email: email,
        avatar: avatar,
      })
    )
    yield put(notificationsActions.set(success))
  } catch (e) {
    console.log(e)
    yield put(notificationsActions.set({ type: 'error', ...error }))
  } finally {
    yield put(actions.response())
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* saga() {
  // Watches for loadRepos actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.request.type, uploadFileSaga)
}
