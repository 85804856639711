import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useAuthComponentSlice } from '../../components/AuthComponent/slice'
import { useLanguageDropdownComponentSlice } from '../../components/LanguageDropdownComponent/slice'
import Notifications from '../../components/Notifications'

export const StaticComponent = ({ children, pageContext: { langIsoCode, langCode } }) => {
  // Actions.
  const dispatch = useDispatch()
  const { actions } = useAuthComponentSlice()
  const { actions: languageDropDownActions } = useLanguageDropdownComponentSlice()

  // Hooks.
  useEffect(() => {
    dispatch(actions.init({ langIsoCode, langCode }))
  }, [dispatch, actions])

  useEffect(() => {
    dispatch(languageDropDownActions.setSelectedLanguage(langCode))
  }, [langCode, dispatch, actions])

  const notificationsComponent = useMemo(() => {
    return <Notifications />
  }, [])

  return (
    <>
      {notificationsComponent}
      {children}
    </>
  )
}
