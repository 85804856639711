import { call, put, takeLatest } from 'redux-saga/effects'
import { signContract } from '../../AuthComponent/slice/api'

import { contractSigningComponentActions } from './index'
import { modalComponentActions } from '../../ModalComponent/slice'

export function* signContractSaga(action) {
  try {
    yield put(contractSigningComponentActions.setIsLoading(true))
    const { payload } = action
    yield call(signContract, payload)
    yield put(contractSigningComponentActions.setApplicationsWhichNeedToBeSigned([]))
    yield put(contractSigningComponentActions.setIsLoading(false))
    yield put(modalComponentActions.setOpen(false))
    yield put(contractSigningComponentActions.setApplicationsWhichNeedToBeSigned([]))
  } catch (e) {}
}

/**
 * Root saga manages watcher lifecycle
 */
export function* contractSigningComponentSaga() {
  // Watches for loadRepos actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(contractSigningComponentActions.signContract.type, signContractSaga)
}
