import { createSlice } from 'utils/@reduxjs/toolkit'
import { useInjectReducer } from 'utils/redux-injectors'
import { ContractSigningComponent } from './types'
import { useInjectSaga } from 'redux-injectors'
import { contractSigningComponentSaga } from './saga'
import { UserApplication } from 'types/runtime'

export const initialState: ContractSigningComponent = {
  applicationsWhichNeedToBeSigned: undefined,
  isLoading: false,
}

type SetApplicationsWhichNeedToBeSigned = {
  type: string
  payload: UserApplication[] | undefined
}

const slice = createSlice({
  name: 'contractSigningComponent',
  initialState,
  reducers: {
    setApplicationsWhichNeedToBeSigned(state, action: SetApplicationsWhichNeedToBeSigned) {
      state.applicationsWhichNeedToBeSigned = action.payload
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    signContract(state, action) {},
    setIsLoading(state, { payload }) {
      state.isLoading = payload
    },
  },
})

export const { actions: contractSigningComponentActions, reducer } = slice

export const useContractSigningComponentSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer })
  useInjectSaga({ key: slice.name, saga: contractSigningComponentSaga })
  return { actions: slice.actions }
}
