/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/styles/fonts.css'
import './src/styles/tailwind.css'
import './src/styles/theme.css'

import React from 'react'
import { StaticComponent } from 'src/layouts/Static'

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return <StaticComponent {...props}>{element}</StaticComponent>
}
