import { createSlice } from '../../../utils/@reduxjs/toolkit'
import { useInjectReducer } from '../../../utils/redux-injectors'
import { IInitialState } from './types'
import shortid from 'shortid'

export const initialState: IInitialState = {
  notifications: [],
}

type TSetPayload = {
  payload: {
    title: string
    message: string
    type?: 'success' | 'error'
  }
}

const slice = createSlice({
  name: 'Notifications',
  initialState,
  reducers: {
    set(state, { payload: { title, message, type = 'success' } }: TSetPayload) {
      state.notifications.push({ id: shortid.generate(), title, message, type })
    },
    unset(state, { payload: { id } }) {
      state.notifications = state.notifications.filter((notification) => notification.id !== id)
    },
  },
})

export const { actions: notificationsActions, reducer } = slice

export const useNotificationsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer })
  return { actions: slice.actions }
}
