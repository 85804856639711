import { createSlice } from 'utils/@reduxjs/toolkit'
import { useInjectReducer } from 'utils/redux-injectors'
import { useInjectSaga } from 'redux-injectors'
import { IInitialState } from './types'
import { saga } from './saga'

export const initialState: IInitialState = {
  isLoading: false,
}

const slice = createSlice({
  name: 'ProfileSettingsForm/Form1',
  initialState,
  reducers: {
    request(state, { payload }) {
      payload && (state.isLoading = true)
    },
    response(state) {
      state.isLoading = false
    },
  },
})

export const { name: key, actions, reducer } = slice

export const useSlice = () => {
  useInjectReducer({ key, reducer })
  useInjectSaga({ key, saga })
  return { actions }
}
