import { all, call, delay, put, takeLatest } from 'typed-redux-saga'
import { authComponentActions } from './index'
import { loginFormComponentActions } from '../../LoginFormComponent/slice'
import { passwordResetFormComponentActions } from '../../PasswordResetFormComponent/slice'
import { loginUser, logoutUser, passwordReset, getApplications, getMe, getToken } from './api'
import { navigate } from 'gatsby'
import { headerComponentActions } from '../../HeaderComponent/slice'
import { downloadsParagraphActions } from '../../Paragraphs/PaedagofixAppComponent/DownloadsParagraph/slice'
import { paedagofixAppComponentActions } from '../../Paragraphs/PaedagofixAppComponent/slice'
import { contractSigningComponentActions } from '../../ContractSigningComponent/slice'

function* handleCheckForContractsWhichNeedToBeSigned() {
  /**
   * If user role and application status differs, the user has not signed the contract.
   */
  const me = yield call(getMe)
  const filter = {
    _and: [
      {
        directus_users_ref: { id: { _eq: me.id } },
      },
      {
        _or: [
          {
            _and: [
              { directus_users_ref: { role: { _neq: '1e717c05-3af1-477e-a0e5-12973ff3856f' } } },
              { status: { _eq: 'practice' } },
            ],
          },
          {
            _and: [
              { directus_users_ref: { role: { _neq: '30f114bd-6760-4b6e-870d-16e2b3c50377' } } },
              { status: { _eq: 'graduate' } },
            ],
          },
        ],
      },
    ],
  }

  const applicationsWhichNeedToBeSigned = yield* call(getApplications, filter)

  yield put(contractSigningComponentActions.setApplicationsWhichNeedToBeSigned(applicationsWhichNeedToBeSigned))
}

function* handleFetchAndSetOfPersonalData() {
  const me = yield call(getMe)
  yield put(
    authComponentActions.setPersonalData({
      firstName: me.first_name,
      lastName: me.last_name,
      email: me.email,
      avatar: me.avatar,
      isCoach: me.is_coach,
      role: me.role,
    })
  )
}

export function* initSaga() {
  const accessToken = yield call(getToken)

  if (!accessToken) {
    yield put(authComponentActions.setIsLoggedIn(false))

    return
  }

  try {
    yield all([
      put(authComponentActions.setIsLoggedIn(true)),
      call(handleCheckForContractsWhichNeedToBeSigned),
      call(handleFetchAndSetOfPersonalData),
    ])
  } catch (e) {
    yield put(authComponentActions.setIsLoggedIn(false))
  }
}

export function* loginUserSaga(action) {
  yield put(loginFormComponentActions.setIsLoading(true))
  const { email, password } = action.payload
  try {
    yield call(loginUser, email, password)

    yield all([
      put(authComponentActions.setIsLoggedIn(true)), // Set user logged in.
      call(handleCheckForContractsWhichNeedToBeSigned), // Handle check for contracts, which need to be signed.
      handleFetchAndSetOfPersonalData(), // Handle fetch and set of personal data.
      put(loginFormComponentActions.setIsLoading(false)), // Set isLoading to false.
    ])
  } catch (e) {
    yield all([
      put(loginFormComponentActions.setLoginFormAsyncError('__LOGIN_FORM_ERROR__')),
      // Set isLoading to false.
      put(loginFormComponentActions.setIsLoading(false)),
    ])
  }
}

export function* logoutUserSaga() {
  try {
    yield all([
      call(logoutUser),
      put(authComponentActions.reset()),
      put(headerComponentActions.reset()),
      put(downloadsParagraphActions.reset()),
      put(paedagofixAppComponentActions.reset()),
    ])
  } catch (e) {}
}

export function* passwordResetSaga(action) {
  const { email, redirectURL } = action.payload
  yield put(passwordResetFormComponentActions.setIsLoading(true))
  yield delay(500)
  yield call(passwordReset, email)
  yield put(passwordResetFormComponentActions.setIsLoading(false))
  yield call(navigate, redirectURL)
}

/**
 * Root saga manages watcher lifecycle
 */
export function* authComponentSaga() {
  // Watches for loadRepos actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield all([
    takeLatest(authComponentActions.init.type, initSaga),
    takeLatest(authComponentActions.loginUser.type, loginUserSaga),
    takeLatest(authComponentActions.logoutUser.type, logoutUserSaga),
    takeLatest(authComponentActions.passwordReset.type, passwordResetSaga),
  ])
}
