import { call, put, takeLatest } from 'redux-saga/effects'
import { downloadsParagraphActions } from '.'
import { loadDownloads } from '../../../../AuthComponent/slice/api'
import { authComponentActions } from '../../../../AuthComponent/slice'
import { normalizeDownloads } from '../../slice/utils'

export function* loadDownloadsSaga() {
  try {
    const downloads = yield call(loadDownloads)
    const normalizedDownloads = normalizeDownloads(downloads)
    yield put(downloadsParagraphActions.setDownloads(normalizedDownloads))
  } catch (e) {
    yield put(authComponentActions.logoutUser())
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* downloadsParagraphSaga() {
  // Watches for loadRepos actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(downloadsParagraphActions.loadDownloads.type, loadDownloadsSaga)
}
