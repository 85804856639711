import { all, call, put, takeLatest } from 'typed-redux-saga'
import { ICoachRequestsRequest, paedagofixAppComponentActions } from '.'
import * as Sentry from '@sentry/react'
import {
  loadLocationsWhichTrainCoaches,
  loadMovementStageData,
  loadSituations,
  pendingCoachRequests,
  postCoachRequest,
} from '../../../AuthComponent/slice/api'
import { normalizeMovementStageData } from './utils'

export function* loadMovementStageDataSaga(action) {
  const { movementStage, langIsoCode } = action.payload
  try {
    const movementStageData = yield call(loadMovementStageData, movementStage, langIsoCode)
    const normalizedMovementStageData = yield call(normalizeMovementStageData, movementStageData, langIsoCode)
    yield put(paedagofixAppComponentActions.setMovementStageData(normalizedMovementStageData))
  } catch (e) {}
}

export function* loadSituationsSaga(action) {
  const { payload } = action
  try {
    const situations = yield* call(loadSituations, payload)
    yield put(paedagofixAppComponentActions.setSituations(situations))
  } catch (e) {}
}

export function* locationsWhichTrainCoachesRequestSaga() {
  try {
    const locationsWhichTrainCoaches = yield* call(loadLocationsWhichTrainCoaches)
    yield put(paedagofixAppComponentActions.locationsWhichTrainCoachesResponse(locationsWhichTrainCoaches))
  } catch (e) {
    Sentry.captureException(e)
  }
}

export function* coachRequestsRequestSaga({ payload }: ICoachRequestsRequest) {
  try {
    const { location_id } = payload
    yield* call(postCoachRequest, { location_id })

    yield put(paedagofixAppComponentActions.coachRequestsResponse(true))
  } catch (e) {
    yield put(paedagofixAppComponentActions.coachRequestsResponse(false))
  }
}

export function* pendingCoachRequestsRequestSaga() {
  try {
    const response = yield* call(pendingCoachRequests)
    yield put(paedagofixAppComponentActions.pendingCoachRequestsResponse(response ? response.length > 0 : false))
  } catch (e) {
    yield put(paedagofixAppComponentActions.pendingCoachRequestsResponse(false))
    Sentry.captureException(e)
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* paedagofixComponentSaga() {
  yield all([
    takeLatest(paedagofixAppComponentActions.loadMovementStageData.type, loadMovementStageDataSaga),
    takeLatest(paedagofixAppComponentActions.loadSituations.type, loadSituationsSaga),
    takeLatest(
      paedagofixAppComponentActions.locationsWhichTrainCoachesRequest.type,
      locationsWhichTrainCoachesRequestSaga
    ),
    takeLatest(paedagofixAppComponentActions.coachRequestsRequest.type, coachRequestsRequestSaga),
    takeLatest(paedagofixAppComponentActions.pendingCoachRequestsRequest.type, pendingCoachRequestsRequestSaga),
  ])
}
