import { createSlice } from 'utils/@reduxjs/toolkit'
import { useInjectReducer } from 'utils/redux-injectors'
import { useInjectSaga } from 'redux-injectors'
import { IInitialState } from './types'
import { saga } from './saga'
import { INotificationsPayload } from 'components/Notifications/types'

export const initialState: IInitialState = {
  isLoading: false,
}

export interface IRequestPayload {
  payload: {
    type: 'POST'
    values: {
      password: string
    }
    notifications: INotificationsPayload
    callback: () => void
  }
}

const slice = createSlice({
  name: 'ProfileSettingsForm/Form3',
  initialState,
  reducers: {
    request(state, { payload }: IRequestPayload) {
      payload && (state.isLoading = true)
    },
    response(state) {
      state.isLoading = false
    },
  },
})

export const { name: key, actions, reducer } = slice

export const useSlice = () => {
  useInjectReducer({ key, reducer })
  useInjectSaga({ key, saga })
  return { actions }
}
