import { createSlice } from '../../../utils/@reduxjs/toolkit'
import { useInjectReducer } from '../../../utils/redux-injectors'
import { useInjectSaga } from 'redux-injectors'
import { authComponentSaga } from './saga'
import { IAuthComponent } from './types'

export const initialState: IAuthComponent = {
  initialized: false,
  isLoggedIn: undefined,
  isLoading: false,
  firstName: null,
  lastName: null,
  email: null,
  avatar: null,
  isCoach: undefined,
  role: undefined,
}

const slice = createSlice({
  name: 'authComponent',
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    init(state, action) {
      state.initialized = true
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loginUser(state, action) {
      state.isLoading = true
    },
    logoutUser(state) {
      state.isLoggedIn = false
    },
    setIsLoggedIn(state, action) {
      state.isLoggedIn = action.payload
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    passwordReset(state, action) {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    register(state, action) {},
    setPersonalData(state, { payload: { firstName, lastName, email, avatar, isCoach, role } }) {
      state.firstName = firstName
      state.lastName = lastName
      state.email = email
      state.avatar = avatar
      state.isCoach = isCoach
      state.role = role
    },
    reset(state) {
      for (const variable in initialState) {
        state[variable] = initialState[variable]
      }
      state.isLoggedIn = false
    },
  },
})

export const { actions: authComponentActions, reducer } = slice

export const useAuthComponentSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer })
  useInjectSaga({ key: slice.name, saga: authComponentSaga })
  return { actions: slice.actions }
}
