import { createSlice } from '../../../../../utils/@reduxjs/toolkit'
import { useInjectReducer } from '../../../../../utils/redux-injectors'
import { IDownloadsParagraph } from './types'
import { useInjectSaga } from 'redux-injectors'
import { downloadsParagraphSaga } from './saga'

export const initialState: IDownloadsParagraph = {
  downloads: null,
}

const slice = createSlice({
  name: 'downloadsParagraph',
  initialState,
  reducers: {
    loadDownloads() {},
    setDownloads(state, action) {
      state.downloads = action.payload
    },
    reset(state) {
      state.downloads = null
    },
  },
})

export const { actions: downloadsParagraphActions, reducer } = slice

export const useDownloadsParagraphSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer })
  useInjectSaga({ key: slice.name, saga: downloadsParagraphSaga })
  return { actions: slice.actions }
}
