import { call, delay, put, takeLatest } from 'redux-saga/effects'
import { actions, IRequestPayload } from '.'
import { updatePassword } from 'components/AuthComponent/slice/api'
import { notificationsActions } from 'components/Notifications/slice'

function* post({ payload }: IRequestPayload) {
  yield delay(1000)
  if (payload.type !== 'POST') return

  const { values } = payload
  yield call(updatePassword, { password: values.password })
  yield put(actions.response())
}

export function* requestSaga(action) {
  const { payload }: IRequestPayload = action
  try {
    if (payload.type === 'POST') yield call(post, { payload })

    yield call(payload.callback)
    yield put(notificationsActions.set(payload.notifications.success))
  } catch (e) {
    yield put(actions.response())
    yield put(notificationsActions.set({ type: 'error', ...payload.notifications.error }))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* saga() {
  // Watches for loadRepos actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.request.type, requestSaga)
}
